<template>
  <div>
    <div class="banner" v-if="bannerList.length > 1">
      <el-carousel height="444px">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <div class="flex-center banner-item" @click="openLink(item.LINK_URL)">
            <img alt="" :src="item.URL">
            <!--            <div class="w1200">-->
            <!--              <div class="banner-title">生猪行业首个全貌数据仓</div>-->
            <!--              <div class="box-info">{{ item.REMARK }}</div>-->
            <!--            </div>-->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="banner" v-else-if="bannerList.length == 1">
      <div class="d-flex">
        <img alt="" @click="openLink(bannerList[0].LINK_URL)" :src="bannerList[0].URL">
      </div>
    </div>
    <!--    <div class="banner">-->
    <!--      <div class="d-flex">-->
    <!--      <img alt="" src="../assets/img/home/banner.png">-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="flex-center grey-box">
      <div class="w1200 ">
        <div class="home-title">
          <img alt="" src="../assets/img/home/title-l.png">
          <div>简介</div>
          <img alt="" src="../assets/img/home/title-r.png">
        </div>
        <div class="box-info t-c">生猪大数据数智云脑旨在通过合理的数据采集及数据治理，向从业者提供数据访问功能，让用户决策更明智，同时形成数据驱动、协同发展的
          <br> 产业生态体系，共同推动生猪产业的高质量发展。
        </div>
        <div class="flex-between brief-line">
          <div class="brief-item">
            <img alt="" src="../assets/img/home/brief1.png">
            <div class="t1">权威核心算法</div>
            <div class="t2">集中算法资源，满足多样化需求</div>
          </div>
          <div class="brief-item">
            <img alt="" src="../assets/img/home/brief2.png">
            <div class="t1">数据共享互利</div>
            <div class="t2">数据多人口收集，多方式共享</div>
          </div>
          <div class="brief-item">
            <img alt="" src="../assets/img/home/brief3.png">
            <div class="t1">资源高效整合</div>
            <div class="t2">打造生猪可持续发展数据体系</div>
          </div>
          <div class="brief-item">
            <img alt="" src="../assets/img/home/brief4.png">
            <div class="t1">全貌产业目录</div>
            <div class="t2">洞悉市场脉搏，赋能产业升级</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-center blue-box">
      <div class="w1200 ">
        <div class="home-title">
          <img alt="" src="../assets/img/home/title-l.png">
          <div>实时数据</div>
          <img alt="" src="../assets/img/home/title-r.png">
        </div>
        <div class="box-info t-c">生猪大数据数智脑的底层数据来自于中国国弄系统的荣易坊，阳光码，粪污系统，智慧养殖的共享数据采集，并对数据保持即使更新，以确保
          <br> 数据的准确性。目前越来越多的个人，企业，平台通过生猪大数据数智云脑获取实时数据，掌握市场情况。
        </div>
        <div class="real-time flex-between">
          <div class="real-time-left">
            <img src="../assets/img/home/real-time.png" alt="">
            <div class="real-time-item real-time1">
              <div>数据资产</div>
              <div>786923</div>
            </div>
            <div class="real-time-item real-time2">
              <div>数据</div>
            </div>
            <div class="real-time-item real-time3">
              <div>模型接口</div>
              <div>49952</div>
            </div>
            <div class="real-time-item real-time4">
              <div>数据服务</div>
              <div>7869223</div>
            </div>
          </div>
          <div class="real-time-right">
            <div class="real-time-data">
              拥有算法 <span>181</span> 例
            </div>
            <div class="real-time-data">
              共享次数 <span>8,934</span> 次
            </div>
            <div class="real-time-data">
              帮助个人 <span>1,734</span> 人
            </div>
            <div class="real-time-data">
              助力企业 <span>2,388</span> 家
            </div>
            <div class="real-time-data">
              接入平台 <span>29</span> 个
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-center grey-box">
      <div class="w1200 ">
        <div class="hot-data">
          <div class="hot-data-left">
            <div class="hot-data-title">数据访问情况</div>
            <div class="hot-chart" ref="hotChart"></div>
          </div>
          <div class="hot-data-right">
            <div class="hot-data-title">访问热门数据Top5</div>
            <div class="rank-list">
              <div class="hot-rank" v-for="(item,index) in rankData">
                <img v-if="index == 0" class="hot-rank-img" src="../assets/img/home/rank1.png" alt="">
                <img v-else-if="index == 1" class="hot-rank-img" src="../assets/img/home/rank2.png" alt="">
                <img v-else-if="index == 2" class="hot-rank-img" src="../assets/img/home/rank3.png" alt="">
                <div v-else class="hot-rank-num">{{ index + 1 }}</div>
                <div>
                  <div class="hot-rank-title">{{ item.resource_name }}</div>
                  <div class="hot-rank-data">
                    <div :style="'width: ' + item.access_cnt / rankData[0].access_cnt * 100 + '%'"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="home-title">-->
<!--          <img alt="" src="../assets/img/home/title-l.png">-->
<!--          <div>荣昌数据</div>-->
<!--          <img alt="" src="../assets/img/home/title-r.png">-->
<!--        </div>-->
<!--        <div class="hot-data rc-data">-->
<!--            <div class="rc-menu">-->
<!--              <div class="on">川渝生猪产业出栏指数</div>-->
<!--              <div>股价指数</div>-->
<!--            </div>-->
<!--            <div class="rc-chart" ref="rcChart1"></div>-->
<!--        </div>-->
        <div class="home-title">
          <img alt="" src="../assets/img/home/title-l.png">
          <div>服务行业</div>
          <img alt="" src="../assets/img/home/title-r.png">
        </div>
        <div class="service-adv flex-between">
          <div class="service-adv-item" @mouseenter="setCurrentIndex(1)" @mouseleave="resetCurrentIndex">
            <img :class="serviceIndex == 1 ? 'on' : ''" src="../assets/img/home/s1-h.png" alt="">
            <img :class="serviceIndex == 1 ? '' : 'on'" src="../assets/img/home/s1.png" alt="">
            <div class="service-info" :class="serviceIndex == 1 ? 'on' : ''">
              <div class="t1">养殖业</div>
              <div class="box-info">
                养殖数据共享为养殖户养殖业带来了前所未有的变革与机遇，实时获取生猪的生长数据、健康状况、饲料消耗等信息，实现精准饲养和管理。
              </div>
            </div>
            <div class="service-info" :class="serviceIndex == 1 ? '' : 'on'">
              <div class="t2">养殖业</div>
              <div class="t3"></div>
            </div>
          </div>
          <div class="service-adv-item" @mouseenter="setCurrentIndex(2)" @mouseleave="resetCurrentIndex">
            <img :class="serviceIndex == 2 ? 'on' : ''" src="../assets/img/home/s2-h.png" alt="">
            <img :class="serviceIndex == 2 ? '' : 'on'" src="../assets/img/home/s2.png" alt="">
            <div class="service-info" :class="serviceIndex == 2 ? 'on' : ''">
              <div class="t1">饲料行业</div>
              <div class="box-info">
                基于数智云脑的共享数据，饲料行业可有效完善生产与销售策略，根据科学配方提升饲料配方及品质，同时精准定位市场需求库存成本。
              </div>
            </div>
            <div class="service-info" :class="serviceIndex == 2 ? '' : 'on'">
              <div class="t2">饲料行业</div>
              <div class="t3"></div>
            </div>
          </div>
          <div class="service-adv-item" @mouseenter="setCurrentIndex(3)" @mouseleave="resetCurrentIndex">
            <img :class="serviceIndex == 3 ? 'on' : ''" src="../assets/img/home/s3-h.png" alt="">
            <img :class="serviceIndex == 3 ? '' : 'on'" src="../assets/img/home/s3.png" alt="">
            <div class="service-info" :class="serviceIndex == 3 ? 'on' : ''">
              <div class="t1">屠宰行业</div>
              <div class="box-info">
                生猪养殖及运输数据共享为屠宰行业带来了新的发展。利用这些数据，屠宰企业可科学制定屠宰计划，加强屠宰企业对生猪来源的追溯能力，让屠宰行业持续健康发展。
              </div>
            </div>
            <div class="service-info" :class="serviceIndex == 3 ? '' : 'on'">
              <div class="t2">屠宰行业</div>
              <div class="t3"></div>
            </div>
          </div>
          <div class="service-adv-item" @mouseenter="setCurrentIndex(4)" @mouseleave="resetCurrentIndex">
            <img :class="serviceIndex == 4 ? 'on' : ''" src="../assets/img/home/s4-h.png" alt="">
            <img :class="serviceIndex == 4 ? '' : 'on'" src="../assets/img/home/s4.png" alt="">
            <div class="service-info" :class="serviceIndex == 4 ? 'on' : ''">
              <div class="t1">监管部门</div>
              <div class="box-info">
                监管部门通过数智云脑的共享数据，可快速掌握市场情况提升监管效率与精准度，同时数据驱动监管，推动监管企业标准化和规范化升级，大力增强监管企业公共服务能力。
              </div>
            </div>
            <div class="service-info" :class="serviceIndex == 4 ? '' : 'on'">
              <div class="t2">监管部门</div>
              <div class="t3"></div>
            </div>
          </div>
          <div class="service-adv-item" @mouseenter="setCurrentIndex(5)" @mouseleave="resetCurrentIndex">
            <img :class="serviceIndex == 5 ? 'on' : ''" src="../assets/img/home/s5-h.png" alt="">
            <img :class="serviceIndex == 5 ? '' : 'on'" src="../assets/img/home/s5.png" alt="">
            <div class="service-info" :class="serviceIndex == 5 ? 'on' : ''">
              <div class="t1">更多行业</div>
              <div class="box-info">
                数智云脑通过整合数据资源，提供标准化的接口及共享服务，旨在打破信息孤岛，推动产业升级，这一目标的实现将对越来越多的行业有广泛而深远的意义。
              </div>
            </div>
            <div class="service-info" :class="serviceIndex == 5 ? '' : 'on'">
              <div class="t2">更多行业</div>
              <div class="t3"></div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="home-bottom flex-center" id="applyAccount">
      <div class="w1200">
        <div class="home-bottom-line flex-between">
          <div class="home-bottom-line-left">开通数据仓账号</div>
          <div class="d-flex">
            <div class="bottom-close-info">
              <img src="../assets/img/home/icon-phone.png" alt="">
              <div>023-85266631</div>
            </div>
            <div class="bottom-close-info">
              <img src="../assets/img/home/icon-time.png" alt="">
              <div>9：00至18：00(工作日)</div>
            </div>
          </div>
        </div>
        <div class="bottom-form flex-between">
          <div class="bottom-form-input">
            <el-input v-model="name" placeholder="您的姓名" style="width: 200px"></el-input>
            <el-input v-model="company" placeholder="您的企业" style="width: 260px"></el-input>
            <el-input v-model="mobile" placeholder="您的联系电话" style="width: 300px"></el-input>
          </div>
          <div class="home-bottom-btn btn-inset" @click="submit">申请开通账号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import rcDate from './rcData.json'
import {AdvPageQuery, FdoStatistic, ResourceTop, UserAddApply} from "../assets/js/api.js"

export default {
  name: 'Home',
  data() {
    return {
      serviceIndex: 1,
      rankData: [],
      newDate: {},
      company: '',
      mobile: '',
      name: '',
      bannerList: [],
      rcDate:rcDate
    };
  },
  mounted() {
    this.initData()
    // this.initRCChart()
    const hash = this.$route.hash;
    if (hash) {
      const position = document.getElementById(hash.substring(1));
      if (position) {
        position.scrollIntoView();
      }
    }
  },
  methods: {
    initData() {
      this.initChart()
      this.rankData = [
        {resource_name: '重庆荣仓地区6月生猪销售价格', access_cnt: 100},
        {resource_name: '生猪宰杀布控', access_cnt: 90},
        {resource_name: '活猪投保', access_cnt: 80},
        {resource_name: '重庆7月生猪销售单位', access_cnt: 70},
        {resource_name: '生猪运输人员表', access_cnt: 60}
      ]


      AdvPageQuery({offsetPage: 0, offsetSize: 10}).then(res => {
        this.bannerList = res.data
      })
    },
    setCurrentIndex(index) {
      this.serviceIndex = index;
    },
    resetCurrentIndex() {
      // 这里假设鼠标移出时重置为第一张图片
      this.serviceIndex = 1;
    },
    initChart() {
      let myChart = echarts.init(this.$refs.hotChart);
      let option;
      option = {
        color: ['#20D3C2', '#5F7292', '#2479F0'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'center',
          left: '60%',
          itemWidth: 9,  // 设置图例标记的图形宽度
          itemHeight: 9, // 设置图例标记的图形高度
          textStyle: {
            color: '#5F7292', // 文字颜色
            fontSize: 14 // 文字大小
          },
          itemGap: 20,
          icon: 'circle',
          orient: 'vertical',
          formatter: function (name) {
            let data = option.series[0].data;
            let total = 0;
            let tarValue;
            for (let i = 0; i < data.length; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            let v = tarValue;
            let p = Math.round((tarValue / total) * 100); //占比
            return `${name}   ${p}%`;
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['55%', '90%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            // data: [
            //   { value: this.newDate.data_asset_cnt, name: '数据资产' },
            //   { value: this.newDate.data_model_cnt, name: '模型接口' },
            //   { value: this.newDate.data_service_cnt, name: '数据服务' }
            // ]
            data: [
              {value: 13, name: '数据资产'},
              {value: 29, name: '模型接口'},
              {value: 58, name: '数据服务'}
            ]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    },
    initRCChart() {
      let myChart = echarts.init(this.$refs.rcChart1);
      let option;
      option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          containLabel: true,
          left: '0',    // 距离左侧的距离可以是像素值或者百分比
          right: '10px',   // 距离右侧的距离
          top: '10px',     // 距离顶部的距离
          bottom: '0'   // 距离底部的距离
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.rcDate.name
        },
        yAxis: {
          type: 'value',
          // axisLabel: {
            // formatter: '{value} °C'
          // }
        },
        series: [
          {
            smooth: true,
            symbolSize: 8,
            type: 'line',
            data:this.rcDate.data,
            markLine: {
              data: [
                {type: 'average', name: 'Avg'}
              ]
            }
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize()
      })

    },
    submit() {
      let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      if (!this.mobile.trim() || !reg.test(this.mobile)) {
        this.$message({message: '请输入正确的联系方式', type: 'warning'});
        return
      }
      UserAddApply({
        username: this.name,
        name: this.name,
        company: this.company,
        mobile: this.mobile
      }).then(res => {
        console.log(res)
        this.$message({message: '已提交申请，请等待管理员审核', type: 'success'});
      }).catch(err => {
        console.log(err)
        this.$message({message: err.msg, type: 'error'});
      })


    },
    openLink(url) {
      if (url) {
        window.open(url)
      }
    }
  }
}
</script>
<style>
@import "../assets/css/home.css";
</style>
