<template>
<div class="footer">Copyright © 2013 - 2024 . All Rights Reserved. 生猪产业大脑产业数据仓平台 版权所有</div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
