import axios from 'axios/index'

const service = axios.create({
	baseURL:URLS.baseApi,
	timeout: 100000 // request timeout
})
service.interceptors.request.use(
	config => {
		var Authorization = localStorage.getItem("token");
		// 在发送请求之前做些什么
		if (Authorization) {
			config.headers['Authorization'] = Authorization
		}
		return config
	},
	error => {
		// 处理请求错误
		console.log(error) // for debug
		return Promise.reject(error)
	}
)
service.interceptors.response.use(
	/**
	 * 如果您想获取http信息，如头或状态
	 * Please return  response => response
	 */

	/**
	 * 通过自定义代码确定请求状态
	 * 您还可以通过HTTP状态代码来判断状态
	 */
	response => {

		if(response.config && response.config.url == "/api/inner/service/downloadmodel"){
			return response;
		}else{
			const res = response.data
			if(res.responseHead.status == '00000'){
				let result = {
					...res.responseBody,
					...res.responseHead
				}
				return result;
			}else{
				return Promise.reject(res.responseHead)
			}
		}
	},
	error => {
		return Promise.reject(error)
	}
)

export default service
