<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  components: {Header, Footer},
}
</script>

<style>
@import './assets/css/common.css';
@import './assets/iconfont/iconfont.css';
</style>
