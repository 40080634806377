import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/supermarket',
    component: () => import('../views/Supermarket.vue'),
  },
  {
    path: '/supermarket/detail',
    component: () => import('../views/supermarket/detail.vue')
  },
  {
    path: '/collection',
    component: () => import('../views/Collection.vue')
  },
  {
    path: '/industrialSystem',
    component: () => import('../views/IndustrialSystem.vue')
  },
  {
    path: '/dataDirectory',
    component: () => import('../views/DataDirectory.vue')
  },
  {
    path: '/dataDirectory/detail',
    component: () => import('../views/dataDirectory/detail.vue')
  },
  {
    path: '/start',
    component: () => import('../views/Start.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
