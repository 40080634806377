import request from './request'
import {formatDateNow} from "@/assets/js/common";

var  requestHead = {
    "clientSystemId": "inner",
    "requestTime": formatDateNow(),
    "token": "70D9B07A4B9E92E72F17D794ACC1215F"
}

export function UserAddApply(data) {
    return request({
        url:'/api/inner/service',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "userAddApply",
                "seqNo": "d204c6001f",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
//资源目录热度排行
export function ResourceTop(data) {
    return request({
        url:'/api/inner/ds',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "resourceTop",
                "seqNo": "02a09008fe",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
//监控数据查询接口(fdoStatistic)
export function FdoStatistic() {
    return request({
        url:'/api/inner/ds',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "fdoStatistic",
                "seqNo": "9ae8369f8c",
                ...requestHead
            },
            "requestBody":{}
        }
    })
}

//产业大模型分页查询(impModelPageQuery)
export function LmpModelPageQuery(data) {
    return request({
        url:'/api/inner/ds',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "lmpModelPageQuery",
                "seqNo": "4706eff2b0",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
//广告分页查询(advPageQuery)
export function AdvPageQuery(data) {
    return request({
        url:'/api/inner/ds',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "advPageQuery",
                "seqNo": "8a25a36659",
                ...requestHead
            },
            "requestBody":data
        }
    })
}

//资源分页查询(resourceListQuery)
export function ResourceListQuery(data) {
    return request({
        url:'/api/inner/ds',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "resourceListQuery",
                "seqNo": "7d08df5412",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
//资源分类查询(indexTypeQuery)
export function IndexTypeQuery(data) {
    return request({
        url:'/api/inner/ds',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "indexTypeQuery",
                "seqNo": "d204c6001f",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
//场景查询(commonSceneQuery)
export function CommonSceneQuery(data) {
    return request({
        url:'/api/inner/ds',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "commonSceneQuery",
                "seqNo": "b60f8a9394",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
//模型详情查询(lmpModelDetail)
export function LmpModelDetail(data) {
    return request({
        url:'/api/inner/service',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "lmpModelDetail",
                "seqNo": "d204c6001f",
                ...requestHead
            },
            "requestBody":data
        }
    })
}

//产业报告下载
// export function Downloadmodel(data) {
//     return request({
//         url:'/api/inner/service/downloadmodel',
//         method:'get',
//         params:data
//     })
// }
//获取产业体系
export function ServiceGetjson() {
    return request({
        url:'/api/inner/service/getjson',
        method:'get',
        // data:{
        //     "requestHead":{
        //         "serviceCode": "downloadmodel",
        //         "seqNo": "d204c6001f",
        //         ...requestHead
        //     },
        //     "requestBody":data
        // }
    })
}
//数据服务详情
export function DspServiceDetail(data) {
    return request({
        url:'/api/inner/service',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "dspServiceDetail",
                "seqNo": "d204c6001f",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
//数据资产详情
export function BapAssetDetail(data) {
    return request({
        url:'/api/inner/service',
        method:'post',
        data:{
            "requestHead":{
                "serviceCode": "bapAssetDetail",
                "seqNo": "d204c6001f",
                ...requestHead
            },
            "requestBody":data
        }
    })
}
