<template>
<div class="flex-center header">
  <div class="w1200  flex-between">
    <div class="header1" @click="goPage(`\home`)">生猪大数据数智云脑</div>
    <div class="header2">
      <router-link to="/home" class="on" >首页</router-link>
      <router-link to="/superMarket" >算法超市</router-link>
      <router-link to="/collection" >采集交换</router-link>
      <router-link to="/industrialSystem" >产业体系</router-link>
      <router-link to="/dataDirectory" >数据目录</router-link>
      <router-link to="/home#applyAccount" style="color: #181818 !important;">开始试用</router-link>
    </div>
    <div class="header3 btn-bg">进入数智云脑</div>
  </div>
</div>
</template>

<script>
export default {
  name: "Header",
  methods:{
    goPage(url,l){
      this.$router.push({ path: url, hash: l });
      // 使用 Vue 的 $nextTick 确保 DOM 更新完成
      this.$nextTick(() => {
        // 使用原生 JavaScript 方法跳转到锚点
        const el = this.$el.querySelector(l);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
